import classNames from 'classnames'
import React from 'react'
import InteractionContext from '../../context/interactionContext'
const { useState, useEffect, useContext } = React

// const isMobile = () => {
//     const ua = navigator.userAgent
//     return /Android|Mobi/i.test(ua)
// }

const Cursor = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [clicked, setClicked] = useState(false)
    const [linkHovered, setLinkHovered] = useState(false)
    const [hidden, setHidden] = useState(false)

    const { hoverText } = useContext(InteractionContext)

    useEffect(() => {
        addEventListeners()
        handleLinkHoverEvents()
        return () => removeEventListeners()
    })

    const addEventListeners = () => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseenter', onMouseEnter)
        document.addEventListener('mouseleave', onMouseLeave)
        document.addEventListener('mousedown', onMouseDown)
        document.addEventListener('mouseup', onMouseUp)
    }

    const removeEventListeners = () => {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseenter', onMouseEnter)
        document.removeEventListener('mouseleave', onMouseLeave)
        document.removeEventListener('mousedown', onMouseDown)
        document.removeEventListener('mouseup', onMouseUp)
    }

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY })
    }

    const onMouseDown = () => {
        setClicked(true)
    }

    const onMouseUp = () => {
        setClicked(false)
    }

    const onMouseLeave = () => {
        setHidden(true)
    }

    const onMouseEnter = () => {
        setHidden(false)
    }

    const handleLinkHoverEvents = () => {
        document.querySelectorAll('a, button').forEach((el) => {
            el.addEventListener('mouseover', () => setLinkHovered(true))
            el.addEventListener('mouseout', () => setLinkHovered(false))
        })
    }

    const cursorClasses = classNames('cursor', {
        'cursor--clicked': clicked,
        'cursor--hidden': hidden,
        'cursor--link-hovered': linkHovered,
    })

    return (
        <div
            className={`${cursorClasses} text-xs uppercase tracking-widest font-ibmplex font-semibold hidden md:block`}
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                fontSize: '7px',
            }}
        >
            {hoverText}
        </div>
    )
}

export default Cursor
