// src/components/Layout.js
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { animated, useSpring } from 'react-spring'
import '../css/index.css'
import Cursor from './Cursor/cursor'

interface Props {
    children: any
}

const Layout = ({ children }: Props) => {
    const animations = {
        background: useSpring({
            width: '100%',
            from: { width: '0%' },
        }),
        stretch: useSpring({
            display: 'block',
            from: {
                display: 'none',
            },
        }),
        bringUp: useSpring({
            delay: 900,
            transform: 'translate3d(0, 0px 0)',
            bottom: '0px',
            from: {
                transform: 'translate3d(0, 100px 0)',
                bottom: '-300px',
            },
        }),
    }

    return (
        <div className="page-wrapper w-screen max-w-full">
            <Cursor />
            <div className="overflow-hidden relative font-pxgrotesk">
                <div className="w-px-85"></div>
                <animated.div
                    className="flex h-full bg-plain-gray min-h-screen relative overflow-hidden"
                    style={animations.background}
                >
                    {children}
                </animated.div>
            </div>
            <CookieConsent
                location="bottom"
                debug={false}
                overlay={false}
                buttonText="I understand"
                cookieName="myAwesomeCookieName2"
                style={{
                    background: '#fff',
                    padding: '10px',
                    color: '#252525',
                }}
                buttonStyle={{
                    color: '#fff',
                    fontSize: '13px',
                    lineHeight: '32px',
                    padding: '8px 24px 8px 24px',
                    backgroundColor: '#964AFD',
                }}
                overlayStyle={{
                    background: 'rgba(0,0,0,.7)',
                }}
                buttonClasses="font-pxgrotesk hover:opacity-75 transition duration-400"
                expires={150}
            >
                <h4 className="font-pxgrotesk text-lg md:mb-0 mb-4">
                    This website uses cookies to enhance the user experience.
                </h4>
                <p className="font-pxgrotesk text-sm text-gray-600">
                    For more information contact us or visit our privacy policy
                    page.
                </p>
            </CookieConsent>
        </div>
    )
}

export default Layout
