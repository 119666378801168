interface Text {
    H1: string
    H2: string
    H3: string
    H4_DASH: string
    H4: string
    P: string
    P_2: string
    LI: string
    LI_2: string
    UL: string
}

const text: Text = {
    H1: 'text-2xl md:text-4xl font-pxgrotesk',
    H2: 'text-xl md:text-3xl font-pxgrotesk mt-10 mb-4',
    H3: 'text-xl md:text-3xl font-pxgrotesk mt-4',
    H4_DASH: 'text-lg md:text-xl font-pxgrotesk dash mt-2',
    H4: 'md:text-lg font-pxgrotesk mt-4',
    P:
        'md:leading-relaxed mt-4 font-ibmplex font-light text-gray-600 md:text-black',
    P_2: 'md:leading-relaxed mt-4 font-ibmplex font-light opacity-75',
    LI:
        'mt-2 font-ibmplex font-light text-gray-600 md:text-black leading-relaxed',
    LI_2: 'mt-2 font-ibmplex font-light leading-relaxed md:text-lg opacity-75',
    UL: 'ml-4 pl-4 list-disc mt-4 md:mt-8',
}

export default text
