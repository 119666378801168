import React from 'react'

interface Props {
    className?: string
}

const Brand = ({ className }: Props) => (
    <div className={className}>
        <svg
            className="w-full h-auto"
            width="40"
            height="38"
            viewBox="0 0 40 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2C18.0751 2 23 6.70101 23 12.5C23 18.299 18.0751 23 12 23"
                stroke="url(#paint0_linear)"
                strokeWidth="2.5"
            />
            <path
                d="M7 36L2 36L2 2L7 2"
                stroke="url(#paint1_linear)"
                strokeWidth="2.5"
            />
            <path d="M33 2H38V36H33" stroke="white" strokeWidth="2.5" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="17.5"
                    y1="2"
                    x2="35.2612"
                    y2="16.5025"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFF0" />
                    <stop offset="0.66868" stopColor="#AF2BFF" />
                    <stop offset="1" stopColor="#0012B8" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="4.5"
                    y1="2"
                    x2="17.2841"
                    y2="4.9306"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFF0" />
                    <stop offset="0.66868" stopColor="#AF2BFF" />
                    <stop offset="1" stopColor="#0012B8" />
                </linearGradient>
            </defs>
        </svg>
    </div>
)

export default Brand
